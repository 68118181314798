import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import EditSchool from 'src/components/edit-school/edit-school';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const EditSchoolPage = () => {
  usePrivateRoute();

  return (
    <Router basepath="/schools/:schoolId">
      <EditSchoolRoute path="/edit" />
    </Router>
  );
};

interface EditSchoolRouteProps extends RouteComponentProps {
  schoolId?: string;
}

const EditSchoolRoute: React.FC<EditSchoolRouteProps> = ({ schoolId }) => {
  return (
    <Layout>
      <SEO title="Edit School" />
      <EditSchool schoolId={schoolId} />
    </Layout>
  );
};

export default EditSchoolPage;
